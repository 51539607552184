import React from "react";
import "./style.scss";
import { BsTwitter } from "react-icons/bs";
import { FaInstagram, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="top">
        <a href="https://twitter.com/glancyhq">
          <BsTwitter size={30} color="#292929" />
        </a>
        <a href="https://www.instagram.com/glancyhq">
          <FaInstagram size={30} color="#292929" />
        </a>
        <a href="https://www.linkedin.com/company/glancyinc">
          <FaLinkedin size={30} color="#292929" />
        </a>
      </div>
      <div className="divider" />
      <div className="bottom">
        <ul>
          <li>
            <a href="/terms">Terms of Service</a>
          </li>
          <li>
            <a href="/privacy">Privacy Policy</a>
          </li>
          <li>
            <a href="mailto:hello@glancy.io?subject=From Glancy Website">hello@glancy.io</a>
          </li>
        </ul>
        <span className="copyright">© 2021 Glancy Inc.</span>
      </div>
    </footer>
  );
};

export default Footer;
