import React from "react";
import "./style.scss";
import Footer from "../../components/common/footer/Footer";
import Navbar from "../../components/common/navbar/Navbar";
import download_icon from "../../assets/images/icons/download.svg";

const Info = () => {
  return (
    <div className="landing__text__info">
      <h1>Video Messaging UNlike ANy other</h1>
      <p>Glancy is a new way to stay connected with those you care about.</p>
      <ul>
        <li>Video & Text messages side by side.</li>
        <li>Get reaction videos when friends see what you’ve shared</li>
        <li>Stay connected like never before</li>
      </ul>
      <a href="https://apps.apple.com/us/app/glancy-video-messaging/id1556533821" target="_blank">
        <img className="download" src={download_icon} alt="download" />
      </a>
    </div>
  );
};
const PlaceHolder = () => {
  return (
    <div className="landing__video__placeholder">
      <div className="image__placeholder">
        <img alt="phone" />
      </div>
    </div>
  );
};
const Landing = () => {
  return (
    <div className="landing__">
      <Navbar />
      <main>
        <div className="content">
          <Info />
          <PlaceHolder />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Landing;
