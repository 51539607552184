import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//import pages
import landing from "./pages/landing/Landing";
const routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={landing} />
      </Switch>
    </Router>
  );
};

export default routes;
