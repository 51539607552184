import React from "react";
import "./style.scss";
import logo from "../../../assets/images/glancy.svg";

const Navbar = () => {
  return (
    <nav className="navbar">
      <img src={logo} alt="logo" />
    </nav>
  );
};

export default Navbar;
